import { Atom, SHARED_UTILS, SHARED_VALIDATIONS } from ":mods";
import { createContactInfoInputs } from "../../form";
import { FormPageProps } from "../../model";
import { TUTORS_FORM_STEPS, TUTORS_SIGNUP_STEPS } from "../../const";
import { createSignal } from "solid-js";

export function ContactPage({ onFormSubmit }: FormPageProps) {
  const [openCalendar, setOpenCalendar] = createSignal(false);
  const defaultDate = SHARED_UTILS.subtractYears(new Date(), 18);
  const [bod, setBOD] = createSignal(
    defaultDate.toLocaleDateString("en-gb", { year: "numeric", month: "long", day: "numeric" })
  );
  const FormInputs = createContactInfoInputs();
  FormInputs.DOB.control.setValue(bod() as any);
  const onPhoneChange = (e) => {
    e.preventDefault();
    const { target } = e;
    const value: any = SHARED_VALIDATIONS.removeAllCharsFromNumber(target.value);
    target.value = value;
  };
  const onBODSelected = (date: Date) => {
    setBOD(date.toLocaleDateString("en-gb", { year: "numeric", month: "long", day: "numeric" }));
    FormInputs.DOB.control.setValue(bod() as any);
  };
  const onCountryCodeSelected = (country: string) => {
    FormInputs.CountryCode.control.setValue(country);
  };
  const onSubmit = async (e: Event) => {
    e.preventDefault();
    const values = FormInputs.Actions.getValuesSnakecase();
    values.dob = new Date(values.dob).toLocaleDateString("en-ca") as any;
    onFormSubmit(values);
  };

  return (
    <section class="flex flex-col justify-center items-center bg#paper w-screen text-16px">
      <Atom.CircleProgress.CircleProgressbar steps={TUTORS_FORM_STEPS} current_step={2} />

      <form class="flex flex-col items-left gap-20px text-16px">
        <h1 class="text-28px font-medium mt-40px mb-20px ">Please provide us with your details</h1>
        <div class="flex flex-col gap-10px">
          <FormInputs.FirstName.Label title="First Name">
            <FormInputs.FirstName.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.FirstName.Label>
          <FormInputs.FirstName.Input
            name="name"
            type="text"
            class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.LastName.Label title="Last Name">
            <FormInputs.LastName.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.LastName.Label>
          <FormInputs.LastName.Input
            name="name"
            type="text"
            class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.Gender.Label title="Select your gender">
            <FormInputs.Gender.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.Gender.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.Gender.Input type="radio" class="" placeholder="" name="gender" id="male" />
            <p class="text-16px">Male</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.Gender.Input type="radio" class="" placeholder="" name="gender" id="female" />
            <p class="text-16px">Female</p>
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.DOB.Label title="Date of birth">
            <FormInputs.DOB.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.DOB.Label>

          <FormInputs.DOB.Input
            type="text"
            class="text-16px bg-inherit border-2 border#p border-solid px-2.5 py-2 mb-2 w-630px h-50px cursor-pointer"
            placeholder=""
            readOnly
            onClick={() => setOpenCalendar(openCalendar() ? false : true)}
          />
          <Atom.External.DatePicker
            show={openCalendar()}
            value={new Date(bod())}
            initialDate={defaultDate}
            onDateSelected={onBODSelected}
          />
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.MobileNo.Label title="Phone Number">
            <FormInputs.MobileNo.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.MobileNo.Label>
          <FormInputs.MobileNo.PhoneInput
            onCountryCodeSelected={onCountryCodeSelected}
            countryCodeControl={FormInputs.CountryCode.control as any}
            onChange={onPhoneChange}
            type="tel"
            class=" bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>

        <div class="flex justify-end">
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 mt-20px mb-80px px-30px py-20px rounded-sm"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
